<template>
  <div class="modal fade" data-backdrop="static" data-keyboard="false" id="newObjectiveEvidence" tabindex="-1">
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
      <form class="modal-content" @submit.prevent="submit">
        <div class="modal-header">
          <div class="modal-title w-100 text-center">
            <h5 class="font-weight-bold">ADD NEW OBJECTIVE EVIDENCE </h5>
          </div>
        </div>
        <div class="modal-body">
          <!-- FOR Category  -->
          <div class="form-group row no-gutters mb-1">
            <label for="extension" class="font-weight-bold col-sm-3 col-form-label">Category</label>
            <div class="col-sm-9">
              <select class="form-control form-control-sm my-2" v-model="oe.category_id">
                <option :value="null">-- Select Category --</option>
                <template v-for="category in oECategories">
                  <option :value="category.id">{{ category.name }} {{ category.description ? '- ' + category.description : '' }}</option>
                </template>
              </select>
            </div>
          </div>
          <!-- FOR Ref to SMS/Regulator -->
          <div class="form-group row no-gutters mb-1">
            <label for="extension" class="font-weight-bold col-sm-3 col-form-label">Ref to SMS/Regulator</label>
            <div class="col-sm-9">
              <input type="text" class="form-control form-control-sm" v-model="oe.reference">
            </div>
          </div>


          <!--          FOR Objective Evidence Description  -->
          <div class="form-group row no-gutters mb-1">
            <label for="extension" class="font-weight-bold col-sm-3 col-form-label">Objective Evidence Description</label>
            <div class="col-sm-9">
              <vue-editor class="my-2" :editor-toolbar="toolbar" v-model="oe.description">
              </vue-editor>
            </div>
          </div>

          <!--          FOR Objective Evidence Description  -->
          <div class="form-group row no-gutters mb-1">
            <label for="extension" class="font-weight-bold col-sm-3 col-form-label">Attachments</label>
            <div class="col-sm-9">
              <table class="table table-sm mb-0">
<!--                <tr style="border: none; width: 50%">-->
<!--                  <td class="text-left" style="border: none">-->
<!--                    <div>-->
<!--                      <input hidden="hidden" type="file" ref="before_attachments"  @change.prevent="addImage('before')" name="before_attachments" id="before_attachments" accept="image/*" multiple>-->
<!--                      <button class="e-btn e-btn-green mt-1 mb-2 e-text-white mx-1" @click="$refs.before_attachments.click()">-->
<!--                        <font-awesome-icon icon="plus"/>-->
<!--                        Add Attachment-->
<!--                      </button>-->
<!--                    </div>-->
<!--                  </td>-->
<!--                  <td class="text-left" style="border: none">-->
<!--                    <div>-->
<!--                      <input hidden="hidden" type="file" ref="after_attachments"  @change.prevent="addImage('after')" name="after_attachments" id="after_attachments" accept="image/*" multiple>-->
<!--                      <button class="e-btn e-btn-green mt-1 mb-2 e-text-white mx-1" @click="$refs.after_attachments.click()">-->
<!--                        <font-awesome-icon icon="plus"/>-->
<!--                        Add Attachment-->
<!--                      </button>-->
<!--                    </div>-->
<!--                  </td>-->
<!--                </tr>-->
              </table>
              <table class="table table-sm table-bordered">
                <thead class="text-center">
                <tr>
                  <th class="align-middle" style="width: 50%">BEFORE</th>
                  <th class="align-middle">AFTER</th>
                </tr>
                </thead>
                <tr>
                  <td>
                    <div style="max-height: 60vh; overflow-y: auto">
<!--                      <template v-for="(image, index) in attachments.before.images">-->
<!--                        <div style="float: left" data-v-71fad98a="" class="col-md-6 col-sm-12 mb-1 justify-content-center align-content-center cursor-pointer p-0"-->
<!--                             @dblclick="viewImage(index, attachments.before.images, {-->
<!--                         'path'      : 'src',-->
<!--                         'caption'   : 'name'-->
<!--                       })">-->
<!--                          <div class="p-2">-->
<!--                            <div data-v-16e2512b="" data-v-71fad98a="" id="ia-attachment-card" class="card p-4">-->
<!--                              <span class="cursor-pointer close-selected-image" aria-hidden="true" @click.stop="deleteImage('before', index)">&times;</span>-->
<!--                              <div :style="{ minWidth: '50%', backgroundImage: 'url(' + image.image_url + ')', width: 'auto', height: '230.5px' }" class="background-contain">-->
<!--                              </div>-->
<!--                            </div>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </template>-->

                      <attachments-handler
                        :key="'before-images-' + attachmentHandlerInstance"
                        ref="before-images-attachments-handler"
                        :accepted-file-types="['image/*']"
                        :hide-file-names="true"
                        :max-height="500"
                      />
                    </div>
                  </td>
                  <td>
                    <div style="max-height: 60vh; overflow-y: auto">
<!--                      <template v-for="(image, index) in attachments.after.images">-->
<!--                        <div style="float: left" data-v-71fad98a="" class="col-md-6 col-sm-12 mb-1 justify-content-center align-content-center cursor-pointer p-0"-->
<!--                             @dblclick="viewImage(index, attachments.after.images, {-->
<!--                         'path'      : 'src',-->
<!--                         'caption'   : 'name'-->
<!--                       })">-->
<!--                          <div class="p-2">-->
<!--                            <div data-v-16e2512b="" data-v-71fad98a="" id="ia-attachment-card" class="card p-4">-->
<!--                              <span class="cursor-pointer close-selected-image" aria-hidden="true" @click.stop="deleteImage('after', index)">&times;</span>-->
<!--                              <div :style="{ minWidth: '50%', backgroundImage: 'url(' + image.image_url + ')', width: 'auto', height: '230.5px' }" class="background-contain">-->
<!--                              </div>-->
<!--                            </div>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </template>-->

                      <attachments-handler
                        :key="'after-images' + attachmentHandlerInstance"
                        ref="after-images-attachments-handler"
                        :accepted-file-types="['image/*']"
                        :hide-file-names="true"
                        :max-height="500"
                      />
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>

          <!-- FOR Corrective Action Request -->
          <div class="form-group row no-gutters mb-1">
            <label for="extension" class="font-weight-bold col-sm-3 col-form-label">Corrective Action Request</label>
            <div class="col-sm-9">
              <vue-editor v-model="oe.ca_request" class="my-2" :editor-toolbar="toolbar">
              </vue-editor>
            </div>
          </div>
          <!--          FOR Rectified on the spot -->
          <div class="form-group row no-gutters mb-1">
            <label for="extension" class="font-weight-bold col-sm-3 col-form-label">Rectified on the spot</label>
            <div class="col-sm-9">
              <input class="ml-0 form-check-input checkbox-input" type="checkbox" value="" id="training_need" :checked="oe.rectified === 1" @click="oe.rectified = setOneOrZero(oe.rectified)">
            </div>
          </div>
          <!--          FOR Rectified Date -->
          <div class="form-group row no-gutters mb-1">
            <label for="extension" class="font-weight-bold col-sm-3 col-form-label">Rectification Date</label>
            <div class="col-sm-9">
              <input type="date" :min="minimumDate"  class="form-control form-control-sm" v-model="oe.rectified_date">
            </div>
          </div>
          <!-- FOR Rectification Comment -->
          <div class="form-group row no-gutters mb-1">
            <label for="extension" class="font-weight-bold col-sm-3 col-form-label">Rectification Comment</label>
            <div class="col-sm-9">
              <vue-editor v-model="oe.rectified_comment" class="my-2" :editor-toolbar="toolbar">
              </vue-editor>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-sm btn-secondary" ref="NewUser_close" @click.prevent="closeModal">Close</button>
<!--          <button type="submit" class="btn btn-sm btn-primary" :disabled="noSelectedAuditTypeOne" :class="noSelectedAuditTypeOne ? 'e-btn-blue-disabled' : ''">Create New Record</button>-->
          <button type="submit" class="btn btn-sm btn-primary">Create New Record</button>
        </div>
      </form>
    </div>
    <image-slider
      :selected-image-index="selectedImageIndex"
      :images="sliderImages"
      :visible="showSlider"
      @hideSlider="resetSlider()"
    >
    </image-slider>
  </div>
</template>

<script>

import {mapActions} from "vuex";
import {AlertService} from "@/services/AlertService";
import DateMixin from "@/mixins/DateMixin";
import ObjectiveEvidenceMixin from "@/mixins/ObjectiveEvidenceMixin";
import NonConformityMixin from "@/mixins/NonConformityMixin";
import requestLoaderMixin from "@/mixins/requestLoaderMixin";
import ImageSlider from "@/components/common/ImageSlider.vue";
import ImageSliderMixin from "@/mixins/ImageSliderMixin";
import AttachmentsHandler from "@/components/common/AttachmentsHandler.vue";

export default {
  name : 'AddObjectiveEvidence',
  components: {AttachmentsHandler, ImageSlider},
  mixins : [DateMixin, ObjectiveEvidenceMixin, NonConformityMixin, requestLoaderMixin, ImageSliderMixin],
  data() {
    return {
      attachmentHandlerInstance : 0,
    }
  },
  methods: {
    ...mapActions([
      'createObjectiveEvidence',
    ]),

    closeModal() {
      this.oe = this.setOeDetailInitialValues();
      $('#newObjectiveEvidence').modal('hide');
    },
    async submit() {

      const beforeImagesProcessing = this.$refs["before-images-attachments-handler"].isProcessingFiles();
      const beforeImagesHasErrors = this.$refs["before-images-attachments-handler"].filesHasError();
      if (beforeImagesHasErrors === true) {
        return;
      }
      if (beforeImagesProcessing === true) {
        return AlertService.warningAlert(`Please wait for the images to finish processing`, 'ATTACHMENTS PROCESSING');
      }
      // console.log(beforeImages, beforeImagesHasErrors);


      this.showLoaderRequest('SAVING OBJECTIVE EVIDENCE, PLEASE WAIT...');
      let param = Object.assign({}, this.oe);
      param.id = this.ncrMainDetailsData[this.$attrs['active-ncr-index']].id;
      param.type = this.$route.params.type;
      const createResponse = await this.createObjectiveEvidence(param);
      if (createResponse.result === false) {
        return AlertService.errorAlert(createResponse.message, 'ADDING OF NEW OBJECTIVE EVIDENCE');
      }

      this.objectiveEvidenceId = createResponse.data.id;

      let errorUploadingResponse = {
        result : true,
        error_message  : null
      };

      const newBeforeFiles = this.$refs["before-images-attachments-handler"].getValidFilesUniqueIds();
      if (newBeforeFiles.length > 0) {
        let beforeFormData = new FormData();
        newBeforeFiles.forEach((file_unique_id) => {
          beforeFormData.append('file_unique_ids[]', file_unique_id);
        });

        const updateImageResponse = await this.updateOEImageAttachment({
          id : this.objectiveEvidenceId,
          type : 'before',
          data : beforeFormData
        });
        if (updateImageResponse.result === false) {
          errorUploadingResponse.result = false;
          errorUploadingResponse.error_message = updateImageResponse.message;
          return AlertService.errorAlert(updateImageResponse.message, 'UPLOADING ATTACHMENT IN OBJECTIVE EVIDENCE');
        }
      }
      const newAfterFiles = this.$refs["after-images-attachments-handler"].getValidFilesUniqueIds();
      if (newAfterFiles.length > 0) {
        let afterFormData = new FormData();
        newAfterFiles.forEach((file_unique_id) => {
          afterFormData.append('file_unique_ids[]', file_unique_id);
        });

        const updateImageResponse = await this.updateOEImageAttachment({
          id : this.objectiveEvidenceId,
          type : 'after',
          data : afterFormData
        });
        if (updateImageResponse.result === false) {
          errorUploadingResponse.result = false;
          errorUploadingResponse.error_message = updateImageResponse.message;
          return AlertService.errorAlert(updateImageResponse.message, 'UPLOADING ATTACHMENT IN OBJECTIVE EVIDENCE');
        }
      }
      // if (this.newAfterFiles.length > 0) {
      //   let afterFormData = new FormData();
      //   this.newAfterFiles.forEach((image, index) => {
      //     afterFormData.append('after[' +  index + ']', image.file);
      //   });
      //   const updateImageResponse = await this.updateOEImageAttachment({
      //     id : this.objectiveEvidenceId,
      //     type : 'after',
      //     data : afterFormData
      //   });
      //   if (updateImageResponse.result === false) {
      //     errorUploadingResponse.result = false;
      //     errorUploadingResponse.error_message = updateImageResponse.message;
      //     return AlertService.errorAlert(updateImageResponse.message, 'UPLOADING ATTACHMENT IN OBJECTIVE EVIDENCE');
      //   }
      // }

      // if (this.imageIdsToDelete.before.length > 0) {
      //   await this.deleteOeAttachments({
      //     oe_id : this.$route.params.oe_id,
      //     ids : this.imageIdsToDelete.before,
      //     type : 'before'
      //   });
      // }
      //
      // if (this.imageIdsToDelete.after.length > 0) {
      //   await this.deleteOeAttachments({
      //     oe_id : this.$route.params.oe_id,
      //     ids : this.imageIdsToDelete.after,
      //     type : 'after'
      //   });
      // }

      await this.getUpdatedNonConformity();
      this.resetImageIdsHolders();
      this.resetAttachments();
      this.closeModal();
      this.hideLoaderRequest();
      AlertService.successAlert('Created Successfully', 'ADDING OF NEW OBJECTIVE EVIDENCE');
    },
    resetAttachments() {
      this.attachments.before.images = [];
      this.attachments.after.images = [];
      this.$refs["before-images-attachments-handler"].resetValues();
      this.$refs["after-images-attachments-handler"].resetValues();
      this.attachmentHandlerInstance++;
    },
  },
  created() {
    // this.initializeAttachments();
  }
}
</script>

<style scoped>
.close-selected-image {
  color: red;
  font-weight: bold;
  font-size: 40px;
  position: absolute;
  top: -11px;
  right: 0;
  opacity: 0.7;
}

.close-selected-image:hover {
  opacity: 1;
}

#ia-attachment-card:hover {
  background-color: #dae8f4;
  transition: 0.5s;
}
</style>
